import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchAndLoad from '../../../hooks/useFetchAndLoad';

import { AuthContext } from '../../../context/auth';

import { IEventDetailAdapter } from '../../../models/event.models';
import { IUsersAdapter } from '../../../models/user.models';

import { useTranslation } from "react-i18next";

import { assignMeetingFromArea, discardUser } from '../controllers/area_service_details.controller';

import { Button, Input, Table } from 'semantic-ui-react';

interface IProps{
    users: IUsersAdapter[]
    status: boolean,
    setReload: any,
    setReloadUsers: any,
    setOpen: any,
    backoffice: string,
    shared: string,
    service?: string,
    meeting?: IEventDetailAdapter;
}

const AreaAdministrationServiceDetailsUsers: React.FC<IProps> = ({ users, status, meeting, service, backoffice, shared, setReload, setReloadUsers, setOpen }) => {
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();

    const [usersFilter, setUsersFilter] = useState<IUsersAdapter[]>();

    return (
        <div style={{margin: '20px'}}> 
            <Input fluid placeholder={t("detail.users_assigned.search_user")} type="text" onChange={(e) => setUsersFilter(users.filter(user => user.Name.includes(e.target.value)))}/>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("detail.users_assigned.user_title")}</Table.HeaderCell>
                        { status && <Table.HeaderCell>{t("detail.users_assigned.status_user_title")}</Table.HeaderCell>}
                        <Table.HeaderCell>{t("detail.users_assigned.actions_user_title")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                        { usersFilter instanceof Object ? (
                            <>
                                { usersFilter?.map(user => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                { user.Name }
                                            </Table.Cell>
                                            { status && (
                                                <Table.Cell>
                                                    { user.Presence === "Available" ? 
                                                        <span className="text-success">{t("detail.users_assigned.status_user_value_available")}</span>
                                                    : 
                                                        <span className="text-secondary">{t("detail.users_assigned.status_user_value_noconnect")}</span>
                                                    }
                                                </Table.Cell>
                                            )}
                                            <Table.Cell>
                                            { meeting !== undefined ? (
                                                    <Button color="red" onClick={async () => {
                                                        await assignMeetingFromArea(meeting.Area, meeting.Code, user.Code, shared , dispatch, callEndpoint)
                                                        setReload(true);
                                                        setOpen(false);
                                                    }}>
                                                        {t("detail.users_assigned.btn_assign")}
                                                    </Button>
                                                ) : (
                                                    <Button 
                                                        color="grey"
                                                        onClick={() => {
                                                            discardUser(user.Code, service || "", shared, dispatch, callEndpoint)
                                                            setReloadUsers(true)
                                                        }}
                                                    >{t("detail.users_assigned.btn_unassigned")}</Button>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                { users?.map(user => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>
                                                { user.Name }
                                            </Table.Cell>
                                            { status && (
                                                <Table.Cell>
                                                    { user.Presence === "Available" ? 
                                                        <span className="text-success">{t("detail.users_assigned.status_user_value_available")}</span>
                                                     : 
                                                        <span className="text-secondary">{t("detail.users_assigned.status_user_value_noconnect")}</span>
                                                    }
                                                </Table.Cell>
                                            )}
                                            <Table.Cell>
                                                { meeting !== undefined ? (
                                                    <Button color="red" onClick={async () => {
                                                        await assignMeetingFromArea(meeting.Area, meeting.Code, user.Code, shared , dispatch, callEndpoint)
                                                        setReload(true);
                                                        setOpen(false);
                                                    }}>
                                                        {t("detail.users_assigned.btn_assign")}
                                                    </Button>
                                                ) : (
                                                    <Button 
                                                        color="grey"
                                                        onClick={() => {
                                                            discardUser(user.Code, service || "", shared, dispatch, callEndpoint)
                                                            setReloadUsers(true)
                                                        }}
                                                    >{t("detail.users_assigned.btn_unassigned")}</Button>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </>
                        )}
                </Table.Body>
            </Table>
        </div>
    )
}

export default AreaAdministrationServiceDetailsUsers;
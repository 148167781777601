export const authConfig = {
  msal: {
    authority:"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4",
    appId: "dev-in2tant-pres-internet",
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}/signin-callback.html`,
    scopes: "gicar_id_token gicar_user_information",
    response_type: "code",

  },
    adfs: {
      identity: {
        authority: "https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4",
        client_id: "dev-in2tant-pres-internet",
        redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/signin-callback.html`,
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        response_type: "code",
        loadUserInfo: false,
        scope: "gicar_id_token gicar_user_information",
        silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/silent-renew.html`,
        automaticSilentRenew: true,
        response_mode: "fragment",
        metadata:{
          issuer:"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth",
          authorization_endpoint:"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth",
          token_endpoint:"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth/token"
        }
      },
    }
}


        
import { Attendee } from "./Attendee";
import { ChangeHistory } from "./ChangeHistory";

export type EventObject = {
  slotId: string;
  start: string;
  end: string;
  createDate: string;
  lastModifiedDate: string;
  status: string;
  videoRoomUrl: string;
  serviceCode: string;
  observations: string;
  comment: string;
  attendees: Attendee[];
  isOtpAvailable?: boolean;
  changeHistory: ChangeHistory[];
  code: string;
};

export const defaultEventObject: EventObject = {
  slotId: "",
  start: "",
  end: "",
  createDate: "",
  lastModifiedDate: "",
  status: "",
  videoRoomUrl: "",
  serviceCode: "",
  observations: "",
  attendees: [],
  changeHistory: [],
  comment: "",
  code: "",
  isOtpAvailable: false,
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
interface Props {
  emailEventFields: any;
  onChangeFields: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Field>
        <label style={{ marginBottom: "20px", marginTop: "20px" }}>
          {" "}
          {t("organitzation.personalitzation.sms_confirmation")}
        </label>
        <textarea
          value={props.emailEventFields.smsEventConfirmation}
          onChange={(smsEventConfirmation: any) =>
            props.onChangeFields({
              smsEventConfirmation: smsEventConfirmation.target.value,
            })
          }
          id="smsEventConf"
        />
      </Form.Field>

      <TextField
        title={t("organitzation.personalitzation.sms_link")}
        value={props.emailEventFields.smsWithLinktoEvent}
        onChange={(smsWithLinktoEvent: any) =>
          props.onChangeFields({ smsWithLinktoEvent })
        }
        id="smsLink"
      />
      <TextField
        title={t("organitzation.personalitzation.sms_otp")}
        value={props.emailEventFields.smsWithOTPBody}
        onChange={(smsWithOTPBody: any) =>
          props.onChangeFields({ smsWithOTPBody })
        }
        id="smsWithOTPBody"
      />
    </div>
  );
};

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "./style.css";
import "./web.config";

import i18n from "./configs/i18n";
import { I18nextProvider } from "react-i18next";

import { toast } from "react-toastify";
import jwt_decode from 'jwt-decode';
import { registerServiceWorker } from "./register-sw";


setInterval(() => { CheckToken() }, 60000)
var expirationTokenIn5Global: number;
const CheckToken = async () => {
  const currentDateTime = new Date().getTime() / 1000;

  if (localStorage.getItem('oidc.user:https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4:dev-in2tant-pres-internet') === "") {
    if (currentDateTime > expirationTokenIn5Global) {
      toast(i18n.t("home.error.tokenExpiresIn5min"), { type: "error"});
    }
  }
  else {
    const tokenInfo: any = localStorage.getItem('oidc.user:https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4:dev-in2tant-pres-internet');
    const decoded: any = jwt_decode(tokenInfo);
    const expirationTokenTime = new Date(decoded?.exp).getTime();
    const expirationTokenIn10 = expirationTokenTime - 600;
    expirationTokenIn5Global = expirationTokenTime - 300;

    if (currentDateTime < expirationTokenTime && currentDateTime > expirationTokenIn10) {
      toast(i18n.t("home.error.tokenExpiresIn10min"), { type: "warning"});
      localStorage.setItem('oidc.user:https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4:dev-in2tant-pres-internet', "");
    }
  }
}
registerServiceWorker()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="loading">
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React, { useContext, useState } from "react";
import { emailEventType } from "./types";
import { encode, decode } from "js-base64";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "../../hooks/useForm";
import {
  defaultOrganitzationInfo,
  OrganitzationInfo,
} from "../../models/OrganitzationInfo";
import { Area } from "../../models/Area";
import { useLocation, useHistory } from "react-router-dom";
import {
  defaultPersonalization,
  deletePersonalitzationContent,
  fetchPersonalitzationContent,
  getOrganization,
  Personalization,
  putOrganizationURL,
  putPersonalitzationContent,
  updateArea,
  putSmsLengthConsult,
  putAreaNotification,
  getAreaNotification,
  getOrganitzationNotification
} from "../../assets/personalitzation";
import { fetchAreaPublished, updateInformersNameArea } from "../../assets/areas";
import {
  getAdminOrganization,
  getAreaInfo,
  getAreaURL,
  putArea,
  putAreaPreferences,
  fetchArea
} from "../../assets/api";
import Screen from "./Screen";
import { toast } from "react-toastify";

export default (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  const defaultForm: any = {
    displayNameCA: "",
    displayNameES: "",
    displayNameEN: "",
    displayNameOC: "",
    email: "",
    phone: "",
    url: "",
    active: false,
  };

  interface Area {
    active: boolean;
    code: string;
    displayName: string;
    email: string;
    languages: Languages[];
    organizationCode?: string;
    phone?: string;
    showName: boolean;
  }

  const initializeArea: Area = {
    active: false,
    code: "",
    displayName: "",
    email: "",
    showName: true,
    languages: [{ displayName: "", culture: "" }],
  };

  const [areaValues, setAreaValues] =
    useForm(initializeArea);

  const [form, setForm] = useForm(defaultForm);

  const [emailEventFields, setEmailEventFields] = useState({
    emailEventConfirmationSubject: "",
    emailEventConfirmation: "",
    emailEventCancelation: "",
    emailWithLinktoEventSubject: "",
    emailWithLinktoEvent: "",
    emailWithOTPSubject: "",
    emailWithOTPBody: "",
    smsWithOTPSubject: "",
    emailEventCancelationSubject: "",
    smsWithOTPBody: "",
    smsEventConfirmation: "",
    smsWithLinktoEvent: "",
  });
  const [organitzationURL, setOrganitzationURL] = useState<string>("");
  const [organitzationURLInformers, setOrganitzationURLInformers] =
    useState<string>("");
  const [loading, setLoading] = useState(false);
  const [organitzation, setOrganitzation] = useState<string>("");
  const [orgInfo, setOrgInfo] = useState<OrganitzationInfo>(
    defaultOrganitzationInfo
  );
  const [eventType, setEvenType] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [showName, setShowName] = useState(true); // para actualizar estado de mostrar o no el nombre de los informadores
  const [genericInformersName, setGenericInformersName] = useState<string>("");
  const [areas, setAreas] = useState<Area[]>([]);
  const [sendExtern, setSendExtern] = useState(false);
  const [organitzationNotification ,setOrganitzationNotification] = useState(false);
  const history = useHistory();

  const [values, setValues, handleInputChange, reset] = useForm({
    entityOrganitzationURL: "",
    entityName: "",
    entityEmailConfirmationSubject: "",
    entityEmailConfirmationBody: "",
    entityEmailLinkSubject: "",
    entityEmailLinkBody: "",
    entitySmsConfirmationSubject: "",
    entitySmsConfirmationBody: "",
    entitySmsLinkSubject: "",
    entitySmsLinkBody: "",
    //entityEmailCancelation: "",
    //entityEmailCancelationSubject: "",
  });

  const { pathname } = location;
  const area = pathname.split("/")[2];

  const onChangeFields = (x: emailEventType) => {
    setEmailEventFields({ ...emailEventFields, ...x });
  };

  function decodeMessages(emailEventFields: emailEventType) {
    setValues({
      entitySmsConfirmationSubject: decode(
        emailEventFields.smsEventConfirmationSubject
      ),
      entitySmsLinkSubject: decode(emailEventFields.smsWithLinktoEventSubject),
    });
    const decodedEventFields: any = Object.fromEntries(
      Object.entries(emailEventFields).map(([key, value]) => [
        key,
        decode(value),
      ])
    );
    onChangeFields(decodedEventFields);
  }

  const retrieveData = async (
    code: string,
    typeOfMeeting: string,
    channelType: string,
    token: string,
    area?: string
  ) => {
    let data: Personalization = defaultPersonalization;
    try {
      const response: Response = await fetchPersonalitzationContent(
        code,
        typeOfMeeting,
        channelType,
        "ca",
        token,
        area
      );
      if (response.ok) {
        let aux = await response.json();
        if (aux.bodyData === null) aux.bodyData = "";
        if (aux.subjectData === null) aux.subjectData = "";
        data = aux;
      }
    } catch (error) {
      console.warn(error);
    }
    return data;
  };


  const updateRecordingsForOrganization = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await updateArea(area, !isRecording, backoffice)
      .then((response) => response.json())
      .then((response) => {
        setIsRecording(!isRecording);

          const { code } = response;
          dispatch(
            fetchAreaPublished(
              code,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );
      });
  };

  const initArea = async (field: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    const areaCode = area;
    try {
      return await fetchArea(areaCode, backoffice)
        .then((response) => {
          if (!response.ok)
            throw new Error(t("edit.area.message.charge_area_error"));

          return response.json();
        })
        .then(async (response) => {
          console.log("REPS", response.showName);
          const resp1 = response;
          if (field === "showName") resp1.showName = !showName;
          else if (field === "genericName") resp1.genericName = genericInformersName;
          return resp1;
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
    setLoading(false);
  };


  const updateInformersNamesForArea = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
      const result =  await initArea("showName");

      updateInformersNameArea(area, result, backoffice)
        .then((response) => response.json())
        .then((response) => {
          setShowName(!showName);

          /*
          const {code} = response;
          dispatch(
            fetchAreaPublished(
              code,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );*/
        })
    }
  


  const updateSendExternNotifications = async () => {
    setSendExtern(!sendExtern);
  }

  const updateAreaNotification = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await putAreaNotification(
      area,
      sendExtern,
      backoffice
    ).then((response) => {
      console.log(response);
    });
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const body = {
        urlServant: organitzationURLInformers,
        urlCitizen: organitzationURL,
        genericName: genericInformersName,
        showName: showName,
      };
      const response = await putAreaPreferences(area, body, token);
      console.log("RESP HANDLE", response);
      if (!response.ok) throw new Error("response bad");
    } catch (error) {
      setLoading(false);
      console.warn(error);
      toast(t("area.personalitzation.error.email_confirmation"), { type: "error"});
    }
    try {
    const result =  await initArea("genericName");
    updateInformersNameArea(area, result, token)
        .then((response) => response.json())
        .then((response) => {
          setGenericInformersName(genericInformersName);

        })
    } catch (error) {
      setLoading(false);
    }

    try {
      updateEmailConfirmation();
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.email_confirmation"), { type: "error"});
    }

    try {
      await updateEmailLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.email_link"), { type: "error"});
    }

    try {
      await updateSmsConfirmation().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.sms_confirmation"), { type: "error"});
    }
    try {
      await consultSmsConfirmationLength().then((response) => {
        console.log(response);
        if (!response) toast(t("area.personalitzation.error.sms_length_confirmation"), {type: "error"});
      });
    } catch (error) {
      //toast(t("area.personalitzation.error.sms_length"), {type: "error"});
      console.log(error);
    }

    try {
      await updateSmsLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsLinkLength().then((response) => {
        console.log(response);
        if (!response) toast(t("area.personalitzation.error.sms_length_link"), {type: "error"});
      });
    } catch (error) {
      //toast(t("area.personalitzation.error.sms_length"), {type: "error"});
      console.log(error);
    }
    try {
      await updateSmsOTP().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsOTPLength().then((response) => {
        console.log(response);
        if (!response) toast(t("area.personalitzation.error.sms_length_otp"), {type: "error"});
      });
    } catch (error) {
      //toast(t("area.personalitzation.error.sms_length"), {type: "error"});
      console.log(error);
    }
    try {
      await updateEmailOTP().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.mail_cancelation"), { type: "error"});
    }

    try {
      await updateEmailCancelled().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("area.personalitzation.error.sms_link"), { type: "error"});
    }

    try {
      await updateAreaNotification().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
    toast(t("area.personalitzation.confirm"), { type: "success"});
  }

  async function updateEmailConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventConfirmation.length === 0 && emailEventFields.emailEventConfirmationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "both", area);
    }
    else if(emailEventFields.emailEventConfirmation.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "body", area);
    }
    else if(emailEventFields.emailEventConfirmationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "subject", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventConfirmationSubject),
        bodyData: encode(emailEventFields.emailEventConfirmation),
      };
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  const updateEmailCancelled = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    
    if(emailEventFields.emailEventCancelation.length === 0 && emailEventFields.emailEventCancelationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "both", area);
    }
    else if(emailEventFields.emailEventCancelation.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "body", area);
    }
    else if(emailEventFields.emailEventCancelationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "subject", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventCancelationSubject) || "",
        bodyData: encode(emailEventFields.emailEventCancelation) || "",
      };

      await putPersonalitzationContent(
        organitzation,
        "MeetingCancelled",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }
  };

  async function updateEmailLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithLinktoEvent.length === 0 && emailEventFields.emailWithLinktoEventSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "both", area);
    }
    else if(emailEventFields.emailWithLinktoEvent.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "body", area);
    }
    else if(emailEventFields.emailWithLinktoEventSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "subject", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithLinktoEventSubject),
        bodyData: encode(emailEventFields.emailWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateSmsConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsEventConfirmation.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Sms", "ca", backoffice, "body", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsConfirmationLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsEventConfirmation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
      await putSmsLengthConsult(
        organitzation,
        "CreateMeeting",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithLinktoEvent.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Sms", "ca", backoffice, "body", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsLinkLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsWithLinktoEvent.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
      await putSmsLengthConsult(
        organitzation,
        "MeetingReminder",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsOTP() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithOTPBody.length === 0){
      await deletePersonalitzationContent(organitzation, "SendOtp", "Sms", "ca", backoffice, "body", area);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsOTPLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if (emailEventFields.smsWithOTPBody.length !== 0) {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
      await putSmsLengthConsult(
        organitzation,
        "SendOtp",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateEmailOTP() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithOTPBody.length === 0){
      await deletePersonalitzationContent(organitzation, "SendOtp", "Mail", "ca", backoffice, "body", area);
    } else {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithOTPSubject),
        bodyData: encode(emailEventFields.emailWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area
      ).then((response) => {
        console.log(response);
      });
    }

  }

  const historyHandleClick = () => {
    history.push('/area',{area});
  };

  const init = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    setLoading(true);
    const typeOfMeetings: string[] = [
      "CreateMeeting",
      "CreateExpressMeeting",
      "MeetingReminder",
      "MeetingCancelled",
      "SendOtp",
    ];

    const channelType: string[] = ["Mail", "Sms"];

    await getAdminOrganization(backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        const code = response.code;
        setOrganitzation(code);
        let urlBody: any = await getAreaURL(area, backoffice).then((response) =>
          response.json()
        );
        console.log("URL BODY", urlBody);
        setOrganitzationURL(urlBody.urlCitizen);
        setOrganitzationURLInformers(urlBody.urlServant);
        setGenericInformersName(urlBody.genericName);
        setShowName(urlBody.showName);
        let infoAboutOrganization: OrganitzationInfo = await getAreaInfo(
          area,
          backoffice
        ).then((response) => response.json());
        setOrgInfo(infoAboutOrganization);
        setIsRecording(infoAboutOrganization.recordMeetingEnabled);
        setShowName(infoAboutOrganization.showName);
        setGenericInformersName(infoAboutOrganization.genericName);
        setAreas(infoAboutOrganization.areas);

        const {
          bodyData: emailEventConfirmation,
          subjectData: emailEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[0],
          backoffice,
          area
        );
        const {
          bodyData: smsEventConfirmation,
          subjectData: smsEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[1],
          backoffice,
          area
        );
        const {
          bodyData: emailWithLinktoEvent,
          subjectData: emailWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[0],
          backoffice,
          area
        );
        const {
          bodyData: smsWithLinktoEvent,
          subjectData: smsWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[1],
          backoffice,
          area
        );
        const {
          bodyData: emailEventCancelation,
          subjectData: emailEventCancelationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[3],
          channelType[0],
          backoffice,
          area
        );
        const { bodyData: emailWithOTPBody, subjectData: emailWithOTPSubject } =
          await retrieveData(
            code,
            typeOfMeetings[4],
            channelType[0],
            backoffice,
            area
          );
        const { bodyData: smsWithOTPBody, subjectData: smsWithOTPSubject } =
          await retrieveData(
            code,
            typeOfMeetings[4],
            channelType[1],
            backoffice,
            area
          );

        decodeMessages({
          smsEventConfirmationSubject,
          smsWithLinktoEventSubject,
          emailEventConfirmationSubject,
          emailEventConfirmation,
          emailEventCancelationSubject,
          emailEventCancelation,
          emailWithLinktoEventSubject,
          emailWithLinktoEvent,
          emailWithOTPSubject,
          smsWithOTPSubject,
          emailWithOTPBody,
          smsWithOTPBody,
          smsEventConfirmation,
          smsWithLinktoEvent,
        });

        await getOrganitzationNotification(code, backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        setOrganitzationNotification(response.externalNotification);
      });
      });

      await getAreaNotification(area, backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        setSendExtern(response.externalNotification);
      });


    setLoading(false);
  };

  return (
    <Screen
      {...props}
      emailEventFields={emailEventFields}
      onChangeFields={onChangeFields}
      decodeMessages={decodeMessages}
      init={init}
      loading={loading}
      values={values}
      organitzationURL={organitzationURL}
      setOrganitzationURL={setOrganitzationURL}
      isRecording={isRecording}
      organitzationURLInformers={organitzationURLInformers}
      setOrganitzationURLInformers={setOrganitzationURLInformers}
      updateRecordingsForOrganization={updateRecordingsForOrganization}
      historyHandleClick={historyHandleClick}
      handleSubmit={handleSubmit}
      updateInformersNamesForArea={updateInformersNamesForArea}
      showName={showName}
      setGenericInformersName={setGenericInformersName}
      genericInformersName={genericInformersName}
      sendExtern={sendExtern}
      updateSendExternNotifications={updateSendExternNotifications}
      organitzationNotification={organitzationNotification}
    />
  );
};

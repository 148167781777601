import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { fetchMSGraph, msalInstance } from "../util/auth";
import { SlotInfo } from "../models/SlotInfo";
import { Slot } from "../models/Slot";
import { SlotCalendar } from "../components/ServicesDisponibilitat/BOCalendar";
import { EnumAPI } from "../enum/EnumAPI";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { formatSlotCalendar } from "../util/date";
import moment from "moment";
import { defaultsAuthorizationModel } from "../models/Authorization";
import { string } from "yup";
import { dispatch } from "rxjs/internal/observable/pairs";

const URL_SLOT = `${process.env.REACT_APP_API_URL}/${EnumAPI.Slots}`;

export type PostSlots = {
  response: boolean | null;
  disponibility_error: boolean | null;
  max_excedeed_error: boolean | null;
};
type DeleteSlots = {
  response: boolean;
  data: string[];
  date: string;
};
type SlotsDayData = {
  day: string;
  slots: Slot[];
};
export type SlotTableDelete = {
  day: string;
  start: string;
  duration: number;
  count: number;
};
interface SlotsState {
  getSlotsCalendar: SlotCalendar[];
  getSlotsByDelete: SlotTableDelete[];
  postSlots: PostSlots;
  deleteSlots: DeleteSlots | null;
}

const initialState: SlotsState = {
  getSlotsByDelete: [],
  getSlotsCalendar: [],
  postSlots: { response: null, disponibility_error: null, max_excedeed_error: null },
  deleteSlots: null,
};


export const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    GET_SLOTS_CALENDAR: (state, action: PayloadAction<SlotCalendar[]>) => {
      state.getSlotsCalendar = action.payload;
    },
    GET_SLOTS_BY_DELETE: (state, action: PayloadAction<SlotTableDelete[]>) => {
      state.getSlotsByDelete = action.payload;
    },
    POST_SLOTS_DATA: (state, action: PayloadAction<PostSlots>) => {
      state.postSlots = action.payload;
    },
    DELETE_SLOTS_DATA: (state, action: PayloadAction<DeleteSlots | null>) => {
      state.deleteSlots = action.payload;
    },
    CLEAN_DELETE_SLOTS_DATA: (state, action: PayloadAction<null>) => {
      state.deleteSlots = action.payload;
    },
  },
});

export const {
  GET_SLOTS_BY_DELETE,
  GET_SLOTS_CALENDAR,
  POST_SLOTS_DATA,
  DELETE_SLOTS_DATA,
  CLEAN_DELETE_SLOTS_DATA,
} = slotsSlice.actions;

export const getReservedMeetings =
  async (
    serviceCode: string,
    year: string,
    month: string,
    token: string
  )  => {
    const url = `${URL_SLOT}/service/${serviceCode}/day?year=${year}&month=${month}`;
      const response = await fetchMSGraph(url, token);
      if (response.ok) {
        const data = await response.json();
        const days: Slot[] = data.filter((slot: Slot) => slot.roomReservedCount > 0);
        return days; 
      }
      
  }

export const getSlotsByDay = async (
  day: string,
  month: string,
  year: string,
  service: string,
  token: string
) => {
  const url = `${URL_SLOT}/service/${service}/day?year=${year}&month=${month}&day=${day}`;
  const response = await fetchMSGraph(url, token);
  let days : Slot[] = [];
  if (response.ok) {
    const data = await response.json();
    days = data.filter((slot: Slot) => ((slot.roomCount - slot.roomReservedCount) > 0));
    
  }
  
  return days;
}

export const getSlotsFromService = async (service: string, slot: string, token: string) => {
  const url = `${URL_SLOT}/service/${service}/${slot}`;
  const response = await fetchMSGraph(url, token);
  return response;
}

export const putSlotsReserved = async (service: string, slot: Slot, token: string) => {
  const url = `${URL_SLOT}/service/${service}/${slot.slotId}`;
  const response = await fetchMSGraph(url, token, EnumHTTPMethod.PUT, JSON.stringify(slot));

  return response;
}

export const fetchGetSlotsCalendar =
  (
    serviceCode: string,
    year: string,
    month: string,
    token: string,
    t: any
  ): AppThunk =>
  async (dispatch) => {
    let responseDispatch: SlotCalendar[] = [];
    const url = `${URL_SLOT}/service/${serviceCode}/day?year=${year}&month=${month}`;
    try {
      let days: any = {};
      const response = await fetchMSGraph(url, token);
      if (response.ok) {
        const data = await response.json();
        data.forEach((slot: Slot) => {
          let backgroundColor = "green";
          const day = moment(formatSlotCalendar(slot.start)).format("DD");
          if (slot.roomReservedCount > 0) {
            days[day] = true;
            if (slot.roomReservedCount != slot.roomCount)
              backgroundColor = "yellow";
            else backgroundColor = "red";
          }
          let a = {
            id: slot.slotId,
            title: `Cita (${slot.roomReservedCount}/${slot.roomCount} ${
              t ? t("slot.reserved") : "reserved"
            })`,
            backgroundColor,
            textColor: "black",
            start: moment(formatSlotCalendar(slot.start)).format(
              "YYYY-MM-DD HH:mm"
            ),
            end: moment(formatSlotCalendar(slot.end)).format(
              "YYYY-MM-DD HH:mm"
            ),
          };
          responseDispatch.push(a);
        });
        Object.keys(days).forEach((day) => {
          let color = "green";
          const slots = data.filter(
            (_: any) => _.date == `${year}${month}${day}`
          );
          const totalRooms = slots.reduce(
            (accum: number, _: any) => accum + _.roomCount,
            0
          );
          const totalReservedRooms = slots.reduce(
            (accum: number, _: any) => accum + _.roomReservedCount,
            0
          );
          if (slots.length > 0) {
            if (totalReservedRooms > 0) color = "yellow";
            if (totalReservedRooms > totalRooms / 2) color = "orange";
            if (totalReservedRooms == totalRooms) color = "red";
            const fullDate = `${year}-${month}-${day}`;
            responseDispatch.push({
              start: fullDate,
              end: fullDate,
              display: "background",
              color,
            });
          }
        });
      }
    } catch (error) {
      console.warn('Error "SLOTS" - Function "fetchGetSlotsMonth" : ', error);
    }
    await dispatch(GET_SLOTS_CALENDAR(responseDispatch));
  };

export const fetchGetCountSlotsByMonth =
  (serviceCode: string, year: string, month: string, token: string): AppThunk =>
  async (dispatch) => {
    if (month.length === 1) {
      month = "0" + month;
    }
    let responseDispatch: SlotTableDelete[] = [];
    const url = `${URL_SLOT}/service/${serviceCode}/month?year=${year}&month=${month}`;
    try {
      const response = await fetchMSGraph(url, token);
      if (response.ok) {
        const data = await response.json();
          data.forEach((slotData: SlotsDayData) => {
            console.log("ENTRA FOR EACH")
            const count = slotData.slots.length;
            let startSlot: string = "";
            let duration: number = 0;
            slotData.slots.every((data) => {
              let splitDate = data.slotId.split("-");
              let start = moment(splitDate[0] + " " + splitDate[1]);
              let end = moment(splitDate[0] + " " + splitDate[2]);
              startSlot = start.format("HH:mm");
              duration = end.diff(start, "minute");
            });
            let a: SlotTableDelete = {
              count: count,
              day: slotData.day,
              duration: duration,
              start: startSlot,
            };
            responseDispatch.push(a);
          });
        }
    } catch (error) {
      console.warn(
        'Error "SLOTS" - Function "fetchGetCountSlotsByMonth" : ',
        error
      );
    }
    await dispatch(GET_SLOTS_BY_DELETE(responseDispatch));
  };

export const fetchPostSlots =
  (token: string, serviceCode: string, arraySlotsInfo?: SlotInfo[]): AppThunk =>
  async (dispatch) => {
    let responseDispatch: PostSlots = { response: null, disponibility_error: null, max_excedeed_error: null };
    const url = `${URL_SLOT}/batch`;
    const body = JSON.stringify({
      serviceCode: serviceCode,
      slotsInfo: arraySlotsInfo,
    });
    try {
      const response = await fetchMSGraph(
        url,
        token,
        EnumHTTPMethod.POST,
        body
      );
      const data = await response.json();
      if (response.ok) {
        responseDispatch.response = true;
      }
      else if (data[0]?.message === "Invalid data. Exist slot with same start time and different duration!!!") {
        responseDispatch.response = false;
        responseDispatch.disponibility_error = true;
      }
      else if (data[0].message === "The maximum number of operations allowed in one batch has been exceeded.") {
        responseDispatch.response = false;
        responseDispatch.max_excedeed_error = true;
      }
      else {
        console.warn("error");
        responseDispatch.response = false;
      }
    } catch (error) {
      responseDispatch.response = false;
      console.log("ERROR", error);
      console.error('Error "SLOTS" - Function "fetchPostSlots" : ', error);
      console.warn('Error "SLOTS" - Function "fetchPostSlots" : ', error);
    }
    await dispatch(POST_SLOTS_DATA(responseDispatch));
  };

export const fetchDeleteSlots =
  (
    serviceCode: string,
    year: string,
    month: string,
    day: string,
    force: boolean,
    token: string
  ): AppThunk =>
  async (dispatch) => {
    let responseDispatch: DeleteSlots | null = null;
    const url = `${URL_SLOT}/service/${serviceCode}/day?year=${year}&month=${month}&day=${day}&forced=${force}`;
    try {
      const response = await fetchMSGraph(url, token, EnumHTTPMethod.DELETE);
      if (response.ok) {
        const data = await response.json();
        responseDispatch = {
          response: true,
          data: data,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
      }
    } catch (error) {
      responseDispatch = {
        response: false,
        data: [],
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      console.warn('Error "SLOTS" - Function "fetchDeleteSlots" : ', error);
    }
    await dispatch(DELETE_SLOTS_DATA(responseDispatch));
  };

export const cleanDeleteSlots = (): AppThunk => async (dispatch) => {
  await dispatch(CLEAN_DELETE_SLOTS_DATA(null));
};

export const cleanPostSlots = (): AppThunk => async (dispatch) => {
  let responseDispatch: PostSlots = { response: null, disponibility_error: null, max_excedeed_error: null };
  await dispatch(POST_SLOTS_DATA(responseDispatch));
};

export const selectSlotsDeleteByMonth = (state: RootState) =>
  state.slots.getSlotsByDelete;
export const selectSlotsCalendar = (state: RootState) =>
  state.slots.getSlotsCalendar;
export const selectPostSlots = (state: RootState) => state.slots.postSlots;
export const selectDeleteSlots = (state: RootState) => state.slots.deleteSlots;

export default slotsSlice.reducer;

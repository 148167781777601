import React from "react";
import { useTranslation } from "react-i18next";
import { Button, List as ListUI, Popup, Table } from "semantic-ui-react";
import Text from "../atomic_components/Text";
interface Props {
  index?: number;
  attendees: any[];
  trigger: string;
}
const getAttendee = (props: any) => {
  if (props.attendees.attendees) {
    const { fullName, personId, phone, email } = props.attendees.attendees[0];
    const { observations } = props.attendees
    return {
      fullName,
      personId,
      phone,
      email,
      observations
    };
  } else {
    return {};
  }

  return {};
};

const CodeCell = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <p>{props.trigger}</p> */}
      {Object.entries(getAttendee(props)).length != 0 ? (
        <Popup
          key={props.index}
          trigger={<p>{props.trigger}</p>}
          position={"right center"}
        >
          {Object.entries(getAttendee(props)).length != 0 && (
            <>
              <Popup.Header>
                {t("detail.meeting.details_code.meeting_data")}
              </Popup.Header>
              <Popup.Content>
                <ListUI>
                  {Object.entries(getAttendee(props)).map(([key, value]) => (
                    <ListUI.Item>
                      <span className={"font-weight-bold"}>{`${t(
                        `detail.meeting.details_code.${key}`
                      )}:    `}</span>
                      {value}
                    </ListUI.Item>
                  ))}
                </ListUI>
              </Popup.Content>
            </>
          )}
        </Popup>
      ) : (
        <Text>{props.trigger}</Text>
      )}
    </>
  );
};
export default CodeCell;

import React from "react";

import { AuthorizationADFS } from "../providers/AuthorizationADFS";
import { AuthorizationBase } from "../providers/AuthorizationBase";
import { AuthorizationMSAL } from "../providers/AuthorizationMSAL";

const createAuthorization = () => {
  const authorizationEnvironment = "ADFS";

  switch (authorizationEnvironment) {
    case "MSAL":
      return new AuthorizationMSAL();
    case "ADFS":
      return new AuthorizationADFS();
    default:
      return new AuthorizationBase();
  }
};

export const AuthContext = React.createContext<AuthorizationBase>(
  createAuthorization()
);

import { statusMeeting } from "../../../util/meeting";
import { Recordings, RecordingsFormatted } from "../types";

export const formatRecordingData = (recording: Recordings) => {
  const format: RecordingsFormatted = {
    serviceName: recording.serviceName,
    areaName: recording.areaName,
    meetingID: recording.meetingID,
    meetingType: recording.meetingType,
    personId: recording.attendees[0].personId,
    meetingCreateDate: recording.meetingCreateDate,
    meetingStartDate: recording.meetingStartDate,
    meetingStatus: recording.meetingStatus,
    servantConnectionDate: recording.servantConnectionDate,
    citizenConnectionDate: recording.citizenConnectionDate,
    co2SaveBus:recording.co2SaveBus,
    co2SaveCoche:recording.co2SaveCoche,
    co2SaveMoto:recording.co2SaveMoto,
    servantCode: recording.servantCode,
    finalizationDate: recording.finalizationDate,
    reason: recording.reason,
    reconnectionCount: recording.reconnectionCount,
    citizenDevice: recording.citizenDevice,
    organization: recording.organization,
    internComment: recording.comment
  }

  return format;
}

export const formatRecording = (recordings: RecordingsFormatted, t: any) => {
  const recordingsFormatToCSV = Object.entries(recordings).map(([ key, value ]: any) => {
    const keyLowercase = key.charAt(0).toLowerCase() + key.slice(1);
    
    let formatValue = value;
    
    if (key === "meetingType") formatValue = t(statusMeeting(value));
    if (key === "meetingStatus") formatValue = t(statusMeeting(value));

    return [t(`recordings.${keyLowercase}`), formatValue];
  })

  return Object.fromEntries(recordingsFormatToCSV);
};
import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";
import {
  AuthorizationModel,
  defaultsAuthorizationModel,
} from "../../models/Authorization";
import { fetchMSGraph } from "../../util/auth";
import AuthLoading from "../Auth/AuthLoading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { authConfig } from "../../configs/authConfig";

interface LoginProps {}

const Login: FC<LoginProps> = ({}: LoginProps): ReactElement => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState<AuthorizationModel>(
    defaultsAuthorizationModel
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await authContext.getAccount();
        const aux: AuthorizationModel = JSON.parse(response);
        setUser(aux);

        if (user.id_token.length === 0) {
          authContext.onSignIn();
        }
      } catch {
        toast(t("home.login.error"), { type: "error"});
      }
    })();
  }, [authContext]);

  return (
    <Fragment>
      {user.id_token !== "" ? (
        <AuthLoading />
      ) : (
        <div style={{ background: "#333" }}></div>
      )}
    </Fragment>
  );
};

export default Login;

import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
interface Props {
  data: any[];
  translationHeader?: (x: string) => string;
}

export default (props: Props) => {
  if (!props.data) return <></>;
  const keys = Object.keys(
    props.data.reduce((accum, data) => ({ ...accum, ...data }), {})
  );

  const getKeyName = (name: string) => {
    return keys.find(key => key === name) || "";
  }

  return (
    <table className="table">
      <thead>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("serviceName")) : getKeyName("serviceName")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("areaName")) : getKeyName("areaName")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("meetingID")) : getKeyName("meetingID")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("meetingType")) : getKeyName("meetingType")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("personId")) : getKeyName("personId")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("meetingCreateDate")) : getKeyName("meetingCreateDate")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("meetingStartDate")) : getKeyName("meetingStartDate")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("meetingStatus")) : getKeyName("meetingStatus")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("servantConnectionDate")) : getKeyName("servantConnectionDate")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("citizenConnectionDate")) : getKeyName("citizenConnectionDate")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("co2SaveBus")) : getKeyName("co2SaveBus")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("co2SaveCoche")) : getKeyName("co2SaveCoche")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("co2SaveMoto")) : getKeyName("co2SaveMoto")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("servantCode")) : getKeyName("servantCode")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("finalizationDate")) : getKeyName("finalizationDate")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("reason")) : getKeyName("reason")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("reconnectionCount")) : getKeyName("reconnectionCount")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("citizenDevice")) : getKeyName("citizenDevice")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("organization")) : getKeyName("organization")}</th>
        <th>{props.translationHeader ? props.translationHeader(getKeyName("internComment")) : getKeyName("internComment")}</th>
      </thead>
      <tbody>
        {props.data?.map((data) => (
          <tr>
            <td>{data.serviceName}</td>
            <td>{data.areaName}</td>
            <td>{data.meetingID}</td>
            <td>{data.meetingType}</td>
            <td>{data.personId}</td>
            <td>{data.meetingCreateDate}</td>
            <td>{data.meetingStartDate}</td>
            <td>{data.meetingStatus}</td>
            <td>{data.servantConnectionDate}</td>
            <td>{data.citizenConnectionDate}</td>
            <td>{data.co2SaveBus}</td>
            <td>{data.co2SaveCoche}</td>
            <td>{data.co2SaveMoto}</td>
            <td>{data.servantCode}</td>
            <td>{data.finalizationDate}</td>
            <td>{data.reason}</td>
            <td>{data.reconnectionCount}</td>
            <td>{data.citizenDevice}</td>
            <td>{data.organization}</td>
            <td>{data.internComment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

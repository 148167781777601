import { AuthorizationBase } from "./AuthorizationBase";


import { AuthorizationModel } from "../models/Authorization";

import { authConfig } from "../configs/authConfig";
import {
  isIE,
  requiresInteraction,
  getAuthRoles,
  fetchMSGraph,
} from "../util/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import {Msal2Provider, Msal2PublicClientApplicationConfig} from '@microsoft/mgt-msal2-provider/dist/es6/exports';
import { Configuration, PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { ProtocolMode} from "@azure/msal-common";


const AUTH_SCOPES = {
  OPENID: "openid",
  OFFLINE_ACCESS: "offline_access",
  PROFILE: "profile",
  USER_READ: "user.read",
};

const useRedirectFlow = isIE();
const AUTH_REQUESTS = {
  LOGIN: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.USER_READ],
  },
  EMAIL: {
    scopes: [],
  },
  REFRESH_TOKEN: {
    scopes: [process.env.REACT_APP_CLIENT_ID],
  },
};

export class AuthorizationMSAL extends AuthorizationBase {
  msalInstance: Msal2Provider;
 
  constructor() {
    super();
    let config:Configuration={
      auth: {
        clientId: authConfig.msal.appId,
        authority: authConfig.msal.authority,
        redirectUri: authConfig.msal.redirectUri,
        protocolMode: ProtocolMode.OIDC,
        knownAuthorities:[authConfig.msal.authority],
        authorityMetadata:"{\"authorization_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth\",\"token_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth/token\",\"end_session_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth/logout\",\"issuer\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth\"}",
      },
      cache:{
        cacheLocation:"localStorage",
        storeAuthStateInCookie:true
      }
    }
    let publicClientApplication = new PublicClientApplication(config)
    this.msalInstance = new Msal2Provider({publicClientApplication});
/*
    this.msalInstance = new Msal2Provider(
    {
      clientId: authConfig.msal.appId,
      options:{
        auth: {
          clientId: authConfig.msal.appId,
          redirectUri: authConfig.msal.redirectUri,
          authority: authConfig.msal.authority,
          authorityMetadata:"{\"authorization_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth\",\"token_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth/token\",\"end_session_endpoint\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth/logout\",\"issuer\":\"https://endpointma.autenticaciogicar4.extranet.gencat.cat/realms/gicarcpd4/protocol/openid-connect/auth\"}",
        }
      },
      customHosts:["https://endpointma.autenticaciogicar4.extranet.gencat.cat"],
        scopes:[AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],

    });*/
    //this.msalInstance.login();
    this.acquireToken(AUTH_REQUESTS.REFRESH_TOKEN, useRedirectFlow);

    this.msalInstance.publicClientApplication.handleRedirectPromise().then(
      async (error: any) => {
        if (error) {
          console.error(error);
        }
      }
    );
  }
  loginPopup(){
    this.msalInstance.login();
  }
  private acquireToken(request: any, redirect: any) {
    return this.msalInstance.publicClientApplication
      .acquireTokenSilent(request)
      .then((response) => {
        this.authenticated = true;
        this.roles = getAuthRoles(response.account);
      })
      .catch((error: any) => {
        if (requiresInteraction(error.errorCode)) {
          this.authenticated = false;
          return redirect
            ? this.msalInstance.publicClientApplication.acquireTokenRedirect(
                request
              )
            : this.onSignIn();
        }

        console.error("Non-interactive error: ", error.errorCode);
        return false;
      });
  }

  onSignIn = () => {
    this.msalInstance.publicClientApplication.loginRedirect({
      scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
      extraQueryParameters: {
        ui_locales: localStorage.getItem("langague") ?? "sv",
      },
    }).then();
  };

  onSignOut = () => {
    this.msalInstance.publicClientApplication.logout();
  };

  getAccount = async () => {
    const user = this.msalInstance.publicClientApplication.getActiveAccount();
    
    const authModel: AuthorizationModel = {
      id_token: JSON.stringify(user?.idToken) || "",
      access_token: "",
      expires_at: user?.idTokenClaims?.exp || 0,
      profile: {
        code: "",
        email: user?.username??"",
        name: user?.username.split("@")[0]??"",
        organization: "",
        roles: [],
      },
    };

    return JSON.stringify(authModel);
  };

  async getTokenForScopes() {
    const scopeBackoffice = "api://in2.in2tant.backoffice.api/.default";
    const scopeShared = "api://in2.in2tant.shared.func/.default";

    const backoffice = await this.msalInstance.getAccessTokenForScopes(
      scopeBackoffice
    );
    const shared = await this.msalInstance.getAccessTokenForScopes(scopeShared);

    const item = Object.keys(localStorage).filter(
      (v) => v.startsWith("msal.") && v.endsWith("idtoken")
    );
    const idToken = localStorage.getItem(item[0]);

    return { backoffice: backoffice, shared: idToken || shared };
  }

  async onAccountStatus(status: string) {
    const { backoffice } = await this.getTokenForScopes();
    const body = JSON.stringify({ activity: status });

    const result = await fetchMSGraph(
      process.env.REACT_APP_API_URL + "/Presence",
      backoffice,
      EnumHTTPMethod.POST,
      body
    ).then((response) => response.json());

    this.accountStatus = result.activity;
  }
}

export interface IEventDetailAdapter{
    Area:           string;
    AssignedTo:     string;
    Attendees:      IAttendeeAdapter[];
    ChangeHistory:  IChangeHistoryAdapter[];
    Code:           string;
    Comment:        string;
    End:            Date;
    OtpAvailable:   boolean;
    Service:        string;
    Slot:           string;
    Start:          Date;
    Status:         EMeetingStatus;
    InTime:         EEventInTime;
    Observations:  string;
}

export interface IEventDetailResponse {
    slotId:           string;
    start:            Date;
    end:              Date;
    createDate:       Date;
    lastModifiedDate: Date;
    isOtpAvailable:   boolean;
    status:           string;
    reason:           null;
    serviceCode:      string;
    comment:          string;
    attendees:        IAttendee[];
    changeHistory:    IChangeHistory[];
    code:             string;
    observations?:     string;
}

export interface IAttendeeAdapter{
    Name:           string;
    Phone:          string;
    ID:             string
    Mail?:           string;
}

export interface IAttendee {
    fullName:     string;
    culture:      string;
    email:        string;
    phone:        string;
    type:         string;
    isOrganizer:  boolean;
    personId:     string;
    personIdType: string;
}

export interface IChangeHistory {
    date:       Date;
    changeType: string;
    changeInfo: string;
}

export interface IChangeHistoryAdapter {
    Date:       Date;
    Type:       string;
    Info:       string;
}

export enum EMeetingStatus {
    SCHEDULED = "Scheduled",
    SCHEDULED_EXPRESS = "ScheduledExpress",
    SCHEDULED_PROGRAMMED = "ScheduledProgrammed",
    SCHEDULED_CUSTOM = "ScheduledCustom",
    WAITING_FOR_CITIZEN = "WaitingForCitizen",
    CITIZEN_WAITING = "CitizenWaiting",
    IN_PROGRESS = "InProgress",
    FINALIZED = "Finalized",
    CANCELLED = "Cancelled",
    NOT_ATTENDEED = "NotAttendeed",
}
  
  export enum EEventInTime{
    EarlyAccess = "EarlyAccess",
    LaterAccess = "LaterAccess",
    InTime = "InTime"
}

import React from "react";

import { IAttendeeAdapter } from "../../../models/event.models";

import { Popup } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

interface IProps{
    code: string,
    comment: string,
    attendees: IAttendeeAdapter[]
}

const AreaAdministrationServiceDetailsMeetingsAttendee = ({code, attendees, comment}: IProps) => {

    const { t } = useTranslation();

    return <Popup header={t("detail.meeting.details_code.meeting_data")} content={
        <>
            {attendees.map((attendee, index) => {
                return (
                    <div style={{ marginTop: '10px', marginBottom: '10px'}}>
                        <span style={{display:'block', marginBottom: '5px'}}><b>{t("detail.meeting.details_code.citizen_title")} {index + 1}</b></span>
                        <b>{ t("detail.meeting.details_code.fullName") }</b> {attendee.Name}
                        <br />
                        <b>{ t("detail.meeting.details_code.personId") }</b> {attendee.ID}
                        <br />
                        <b>{ t("detail.meeting.details_code.phone") }</b> {attendee.Phone}
                        <br />
                        <b>{ t("detail.meeting.details_code.email") }</b> {attendee?.Mail}
                    </div>
                )
            })}
            <b>{t("detail.meeting.details_code.observations")}</b> <br/> {comment}
        </>
    } trigger={<p>{code}</p>} />

}

export default AreaAdministrationServiceDetailsMeetingsAttendee;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List as ListUI, Popup, Table } from "semantic-ui-react";
interface Props {
  meeting: any;
};

const MoreInfoAction = (props: Props) => {

  const { t } = useTranslation();
  const attendee = props.meeting.attendees ? props.meeting.attendees[0] : {};
  const information = {
    fullName: attendee.fullName,
    personId: attendee.personId,
    phone: attendee.phone,
    email: attendee.email,
    observations:
      props.meeting.observations ||
      t("detail.meeting.status_column.observations_no"),
  };
  return (<Popup
    key={"info"}
    trigger={
      <Button
        color={"green"}
        size={"small"}
        onClick={(e) => { }}
        icon={"info"}
      />
    }
    position={"right center"}
  >
    {Object.entries(information).length !== 0 && (
      <>
        <Popup.Header>
          {t("detail.meeting.details_code.meeting_data")}
        </Popup.Header>
        <Popup.Content>
          <ListUI>
            {Object.entries(information).map(([key, value]) => (
              <ListUI.Item>
                <span className={"font-weight-bold"}>{`${t(
                  `detail.meeting.details_code.${key}`
                )}:    `}</span>
                {value}
              </ListUI.Item>
            ))}
          </ListUI>
        </Popup.Content>
      </>
    )}
  </Popup>);
};
export default MoreInfoAction
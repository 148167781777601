import { Attendee } from "../../../types/dashboard";

export interface Recordings {
  areaName: string;
  meetingID: string;
  meetingType: string;
  citizenConnectionDate: Date;
  citizenDevice: string;
  co2SaveBus:string;
  co2SaveCoche:string;
  co2SaveMoto:string;
  meetingCreateDate: Date;
  finalizationDate: Date;
  reconnectionCount: string;
  servantCode: string;
  servantConnectionDate: Date;
  serviceName: string;
  meetingStartDate: Date;
  reason: string;
  meetingStatus: string;
  attendees: Attendee[]
  organization?: string;
  comment?: string;
}

export interface RecordingsFormatted{
  serviceName: string;
  areaName: string;
  meetingID: string;
  personId: string;
  meetingType: string;
  citizenConnectionDate: Date;
  citizenDevice: string;
  co2SaveBus:string;
  co2SaveCoche:string;
  co2SaveMoto:string;
  meetingCreateDate: Date;
  finalizationDate: Date;
  reconnectionCount: string;
  servantCode: string;
  servantConnectionDate: Date;
  meetingStartDate: Date;
  reason: string;
  meetingStatus: string;
  organization?: string;
  internComment?: string;
}

export const defaultRecordingsFormatted: RecordingsFormatted = {
  areaName: "",
  citizenConnectionDate: new Date(),
  citizenDevice: "",
  co2SaveBus:"",
  co2SaveCoche:"",
  co2SaveMoto:"",
  finalizationDate: new Date(),
  meetingID: "",
  meetingCreateDate: new Date(),
  meetingStartDate: new Date(),
  meetingStatus: "",
  meetingType: "",
  personId: "",
  reason: "",
  reconnectionCount: "",
  servantCode: "",
  servantConnectionDate: new Date(),
  serviceName: "",
  internComment: ""
}

export interface ContinuationToken {
  token: string;
  range: any;
}

export interface HeadersCSVReport {
  label: string;
  key: string;
}

export interface CSVReport {
  data: any;
  headers: HeadersCSVReport[];
  filename: string;
}
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams  } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useFetchAndLoad from "../../hooks/useFetchAndLoad";

import { LayoutContext } from "../../context/LayoutContext";

import moment from "moment";

import { IUsersAdapter } from "../../models/user.models";
import { IEventDetailAdapter } from "../../models/event.models";

import { getMeetingsFromDate, getUsersFromArea, getUsersFromService } from "./controllers/area_service_details.controller";

import LayoutContainer from "../../components/Layout/LayoutContainer";
import { TabList } from "../../components/Tabs";

import { AreaAdministrationServiceDetailsMeetings, AreaAdministrationServiceDetailsUsers, AreaAdministrationServiceDetailsAddEmployeesModal } from './components';

import './styles/component.css'
import { AuthContextType } from "../../types/auth";
import { Button } from "../../components/Button/Button";

interface IProps{
    account: AuthContextType
}

const AreaAdministrationServicesDetails = ({account}: IProps) => {
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();
    const history = useHistory();
    const params: any = useParams();

    const { serviceCode } = params;
    const { areaCode } = params;

    const [users, setUsers] = useState<IUsersAdapter[]>();
    const [usersArea, setUsersArea] = useState<IUsersAdapter[]>();
    const [meetings, setMeetings] = useState<IEventDetailAdapter[]|boolean>();
    const [date, setDate] = useState(moment().format("YYYYMMDD"));
    const [reload, setReload] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(true);
    const [addEmployees, setAddEmployees] = useState(false);
    const [backoffice, setBackoffice] = useState("");
    const [shared, setShared] = useState("");

    const arrayButtonsArea = [
        {
          icon: "setting",
          message: "detail.btn_settings",
          onClick: () => history.push(`/area/${areaCode}/services/${serviceCode}/settings`),
        },
        { 
          icon: "arrow right",
          message: "detail.btn_availability",
          onClick: () => history.push(`/area/${areaCode}/services/${serviceCode}/disponibilitat`),
        },
        {
          icon: "edit",
          message: "detail.btn_modify",
          onClick: () => history.push(`/services/${serviceCode}/edit`),
        },
        {
          icon: "plus",
          message: "detail.btn_add_users",
          onClick: () => setAddEmployees(true),
        },
        {
          icon: "arrow left",
          message: "go_back",
          onClick: () => {
            const currentURL = window.location.href;
            const splitUrl = currentURL.split("/");
            const indexArea = splitUrl.findIndex((_) => "area" == _);
            const area = splitUrl[indexArea + 1];
            history.push(`/area`, { area });
          },
        },
      ];

    useEffect(() => {
        const getTokens = async () => {

            const { backoffice } = await account.getTokenForScopes();
            const { shared } = await account.getTokenForScopes();

            setBackoffice(backoffice);
            setShared(shared);
        }

        getTokens();
    }, [])

    useEffect(() => {
        const getUsers = async () => {
            var usersResult: IUsersAdapter[] = await getUsersFromService(serviceCode, backoffice, dispatch, callEndpoint);
            var usersAreaResult: IUsersAdapter[] = await getUsersFromArea(areaCode, backoffice, dispatch, callEndpoint);

            for (let i = 0; i < usersResult.length; i++) {
              usersAreaResult = usersAreaResult.filter(obj => obj.Code !== usersResult[i].Code);
            }

            setUsers(usersResult);
            setUsersArea(usersAreaResult);
            setReloadUsers(false)
        }

        getUsers();
    }, [reloadUsers === true, backoffice])

    useEffect(() => {
        const getMeetingsWhenDateChange = async () => {
                const meetingsResult: IEventDetailAdapter[] | boolean = await getMeetingsFromDate(moment(date).format("YYYYMMDD"), moment(date).format("YYYYMMDD"), areaCode, serviceCode, backoffice, dispatch, callEndpoint);

                setMeetings(meetingsResult);
                setReload(false);
        }

        getMeetingsWhenDateChange();

    }, [date, reload === true, backoffice])

    return (
        <>
            <LayoutContainer.Main>
                <>
                    <TabList tabs={
                        [{
                            title: t("detail.meeting.title.title"),
                            children: <AreaAdministrationServiceDetailsMeetings 
                                meetings={meetings} 
                                users={users} 
                                date={date}
                                backoffice={backoffice}
                                shared={shared}
                                setDate={setDate}
                                setReload={setReload} 
                                />
                        },{
                            title: t("detail.users_assigned.users_assigned_title"),
                            children: <AreaAdministrationServiceDetailsUsers 
                                users={users} 
                                status={true} 
                                service={serviceCode} 
                                backoffice={backoffice}
                                shared={shared}
                                setReload={setReload} 
                                setReloadUsers={setReloadUsers}
                                />
                        }]}
                    />
                </>
            </LayoutContainer.Main>
            <LayoutContainer.Article>
              {arrayButtonsArea.map(button => 
                <Button
                    message={button.message}
                    icon={button.icon}
                    onClick={button.onClick}
                />
              )}
            </LayoutContainer.Article>
            <AreaAdministrationServiceDetailsAddEmployeesModal users={users} usersArea={usersArea} area={areaCode} service={serviceCode} backoffice={backoffice} open={addEmployees} setOpen={setAddEmployees} setReloadUsers={setReloadUsers}/>
        </>
    )
}

export default AreaAdministrationServicesDetails;